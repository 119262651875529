import Image from 'next/image'

const Answer = ({ isSelected = false, title, image, onClick }) => (
  <div className="w-full max-w-150 flex flex-col items-center gap-3">
    <button
      onClick={onClick}
      className={`${
        isSelected
          ? 'bg-white border-primary shadow-lg'
          : 'bg-alt-bg border-transparent'
      } border-2 rounded-full p-6`}
    >
      <Image
        key={`${title}-answer-image`}
        src={image}
        alt={`${title} answer image`}
        width={74}
        height={74}
        style={{
          maxWidth: 74,
          height: 74,
        }}
      />
    </button>
    <p className="text-lg font-semibold text-center line-clamp-3">{title}</p>
  </div>
)

export default Answer
