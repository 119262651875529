import React from 'react'
import Lottie from 'react-lottie-player'
import Button from '../common/Button'

const CallToAction = ({
  title,
  description,
  lottieAnimation,
  positiveHref,
  positiveActionLabel,
  positiveAction,
  negativeActionLabel,
  negativeAction,
}) => (
  <div className="bg-topic-green rounded-lg px-5 pt-1 pb-6 md:pt-4 md:pb-0 md:pr-24 my-3 mx-4 md:mx-auto max-w-700 flex flex-col md:flex-row justify-center items-center md:items-start gap-0 md:gap-2">
    <Lottie
      loop
      animationData={lottieAnimation}
      play
      style={{ width: 150, height: 150 }}
    />
    <div className="text-center md:text-left -mt-2 md:-mt-0">
      <p className="text-lg font-bold text-white">{title}</p>
      <p className="text-sm text-white mb-4">{description}</p>
      <div className="flex flex-row justify-center md:justify-start">
        <Button
          href={positiveHref}
          targetBlank={positiveHref}
          onClick={positiveAction}
          small
        >
          {positiveActionLabel}
        </Button>
        <button
          onClick={negativeAction}
          className="text-white text-opacity-80 text-sm py-2 px-6 bg-transparent"
        >
          {negativeActionLabel}
        </button>
      </div>
    </div>
  </div>
)

export default CallToAction
