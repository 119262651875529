import React, { useState } from 'react'
import { useSWRConfig } from 'swr'
import useAnswers from '../../../lib/useAnswers'
import useUser from '../../../lib/useUser'
import Button from '../../common/Button'
import BaseModal from '../../common/modal/BaseModal'
import { useCacheKey } from '../../context/CacheKey'
import Answer from './Answer'

const QuestionnaireModal = ({ isOpen, onRequestClose, handleSkip }) => {
  const { mutate } = useSWRConfig()
  const cacheKey = useCacheKey()
  const { user, updateUser } = useUser()
  const { answers } = useAnswers(isOpen)
  const [selectedAnswers, setSelectedAnswers] = useState([])

  const selectAnswer = (id, title) => {
    setSelectedAnswers((prevValue) => {
      let value = [...prevValue, { id, title }]
      if (prevValue.find((element) => element.id === id)) {
        value = prevValue.filter((element) => element.id !== id)
      }

      return value
    })
  }

  const submitAnswers = async () => {
    try {
      await updateUser({
        ...user,
        data: { ...user.data, answers: selectedAnswers },
      })
      mutate(cacheKey.value)
      onRequestClose()
    } catch (error) {
      console.log('error saving onboarding questionnaire')
    }
  }

  return (
    <BaseModal
      isOpen={isOpen}
      onRequestClose={() => {
        onRequestClose()
      }}
      closeIcon
      title="What are you hoping to get out of this experience?"
      titleStyle="w-full"
      contentStyle={{ placeItems: 'start' }}
    >
      <p className="text-sm ml-2 md:ml-0">
        We’ll use this information to enhance your Community Lab journey.
      </p>
      <p className="text-sm font-bold pt-2 pb-6 ml-2 md:ml-0">
        You can select multiple options.
      </p>
      <div className="w-full flex flex-row flex-wrap justify-center items-start gap-14">
        {answers?.map((answer) => (
          <Answer
            key={`answer-${answer.id}`}
            title={answer.title}
            image={answer.image}
            isSelected={selectedAnswers.find(
              (element) => element.id === `${answer.id}`
            )}
            onClick={() => selectAnswer(`${answer.id}`, answer.title)}
          />
        ))}
      </div>
      <div className="w-full flex flex-row gap-8 justify-end items-center mt-12">
        <button onClick={handleSkip}>I don't want to answer</button>
        <Button
          onClick={submitAnswers}
          primary
          disabled={selectedAnswers.length <= 0}
        >
          Submit
        </Button>
      </div>
    </BaseModal>
  )
}

export default QuestionnaireModal
