import Image from 'next/image'
import { useState } from 'react'
import useUser from '../../../lib/useUser'
import Button from '../Button'
import BaseModal from './BaseModal'

function OnboardingModal({ user }) {
  const [showModal, setShowModal] = useState(user.onboarded_at == null)
  const { updateUser } = useUser()

  const onClose = async () => {
    const body = { ...user, onboarded_at: new Date().toISOString() }
    setShowModal(false)
    try {
      await updateUser(body)
    } catch (error) {
      console.error('An unexpected error happened:', error)
    }
  }

  return (
    <BaseModal closeIcon isOpen={showModal} onRequestClose={() => onClose()}>
      <div className="w-full">
        <div className="bg-white rounded-lg p-4 mb-4">
          <div className="flex flex-row gap-2">
            <Image
              key="andrew"
              src="/andrew.png"
              alt="Andrew avatar"
              width={30}
              height={30}
              priority
              className="rounded-full w-16 h-16"
            />
            <div className="flex flex-col">
              <p className="text-sm text-gray-600 my-1">
                I'm Andrew, founder of Community Lab. I created Community Lab to
                breakdown silos, empower communities and to make collaboration
                easier.
              </p>
              <p className="text-sm text-gray-600 my-1">
                I would love to hear from you. If you have any feedback, ideas
                or need any help, please reach out. Let's make an impact 🚀
              </p>
            </div>
          </div>
        </div>
        <div>
          <Button
            primary
            className="mt-6"
            type="submit"
            onClick={() => onClose()}
          >
            Continue
          </Button>
        </div>
      </div>
    </BaseModal>
  )
}

export default OnboardingModal
