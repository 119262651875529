import Image from 'next/image'
import links from '../../config/links'
import useUser from '../../lib/useUser'
import InfoItem from '../common/InfoItem'
import ChatAction from '../profile/user/actions/ChatAction'

const HomeFeedEmpty = () => {
  const { user } = useUser()

  const isUserNew = () => {
    if (!user?.onboarded_at) return false

    const onboardedDate = new Date(user.onboarded_at)
    const currentDate = new Date()
    const differenceInHours = (currentDate - onboardedDate) / (1000 * 60 * 60)

    return differenceInHours <= 48
  }

  const title = isUserNew() ? 'Hello new user 👋' : "Let's get started"

  return (
    <div className="flex flex-col w-full mb-4 px-2 md:px-0">
      <h2 className="text-xl font-bold mb-2">{title}</h2>
      <div className="bg-white rounded-lg p-4 mb-4">
        <div className="flex flex-row gap-2">
          <Image
            key="andrew"
            src="/andrew.png"
            alt="Andrew avatar"
            width={60}
            height={60}
            priority
            className="rounded-full w-24 h-24"
          />
          <div className="flex flex-col">
            <p className="text-sm text-gray-600 my-1">
              I'm Andrew, founder of Community Lab. I created Community Lab to
              breakdown silos, empower communities and to make collaboration
              easier.
            </p>
            <p className="text-sm text-gray-600 my-1">
              I would love to hear from you. If you have any feedback, ideas or
              need any help, please reach out. Let's make an impact 🚀
            </p>
          </div>
        </div>
        <div className="flex flex-row align-middle items-center w-fill justify-end gap-4 mt-2">
          <a
            target="_blank"
            className="text-gray-600 underline text-sm cursor-pointer"
            href={links.andrewEmail}
            rel="noreferrer"
          >
            Send me an email instead
          </a>
          <ChatAction profileId={links.andrewProfileId} />
        </div>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-2 w-full mb-2">
        <InfoItem
          href={links.clCluster}
          icon="cluster"
          title="Join support cluster"
          text="Clusters are used to group similar communities together"
        />
        <InfoItem
          href={links.supportGroup}
          icon="group"
          title="Join our support group"
          bgColor="bg-topic-blue"
          text="Our support group is here for you and a good place to start"
        />
        <InfoItem
          href="/search"
          icon="search"
          title="Search for anything"
          bgColor="bg-topic-purple"
          text="Got an idea of what you're looking for? Search for it here"
        />
        <InfoItem
          href="/discover"
          icon="discover"
          title="View the discover tab"
          bgColor="bg-topic-yellow"
          text="Find exciting new communities to join and clusters to follow"
        />
      </div>
      <p className="text-gray-600 text-center mt-2 italic">
        Once you join some groups or follow clusters this will be your home feed
        with posts and events relevant to you.
      </p>
    </div>
  )
}

export default HomeFeedEmpty
