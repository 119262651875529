import { useState } from 'react'
import { useSWRConfig } from 'swr'
import useAnswers from '../../../lib/useAnswers'
import useUser from '../../../lib/useUser'
import PeopleWaving from '../../../public/lottie/PeopleWaving.json'
import CallToAction from '../../callToAction/CallToAction'
import { useCacheKey } from '../../context/CacheKey'
import QuestionnaireModal from './QuestionnaireModal'

const QuestionnaireCTA = () => {
  const [questionnaireModalVisible, setQuestionnaireModalVisible] =
    useState(false)
  const { mutate } = useSWRConfig()
  const cacheKey = useCacheKey()
  const { answers } = useAnswers(questionnaireModalVisible)
  const { user, updateUser } = useUser()

  const toggleQuestionAirModal = () => {
    setQuestionnaireModalVisible((prevValue) => !prevValue)
  }

  const handleSkip = async () => {
    try {
      await updateUser({ ...user, data: { ...user.data, answers: ['null'] } })
      mutate(cacheKey.value)
      setQuestionnaireModalVisible(false)
    } catch (error) {
      console.log('error skipping onboarding questionnaire')
    }
  }

  if (user?.data?.answers?.length > 0) {
    return null
  }

  if (!answers || answers?.length <= 0) {
    return null
  }

  return (
    <>
      <QuestionnaireModal
        onRequestClose={toggleQuestionAirModal}
        isOpen={questionnaireModalVisible}
        handleSkip={handleSkip}
      />
      <CallToAction
        lottieAnimation={PeopleWaving}
        title="Let’s get to know each other"
        description="There’s many reasons to join our platform. Telling us what you hope to achieve from being here will help us personalise the experience."
        positiveActionLabel="Let's talk"
        positiveAction={() => setQuestionnaireModalVisible(true)}
        negativeActionLabel="Don't show me this"
        negativeAction={handleSkip}
      />
    </>
  )
}

export default QuestionnaireCTA
