import React from 'react'
import useChats from '../../../../lib/api/useChats'
import useMemoizedPathFor from '../../../../lib/pathFor'
import Button from '../../../common/Button'

const ChatAction = ({ profileId }) => {
  const { chats } = useChats({ dm_user_id: profileId })
  const chatPage =
    (chats?.length || 0) > 0 ? chats[0].slug : `new?user_id=${profileId}`
  const chatHref = useMemoizedPathFor('chat', chatPage)

  return (
    <Button primary tiny href={chatHref}>
      Send message
    </Button>
  )
}

export default ChatAction
