import useSWR from 'swr'
import fetchJson from './fetchJson'

export default function useAnswers(shouldFetch = true) {
  const { error, data, isValidating } = useSWR(
    shouldFetch ? `/api/answers.json` : null,
    fetchJson,
    {
      refreshInterval: 3600000,
      dedupingInterval: 3600000,
    }
  )
  const answers = data?.answers

  return {
    answers,
    isValidating,
    error,
  }
}
