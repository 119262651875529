import usePaginated from './usePaginated'

/**
 * @param queryParams {Object} - Optional query parameters
 * @option {number} [queryParams.dm_user_id] - Filter chats to DMs with this user
 */
export default function useChats(queryParams) {
  const { items: chats, ...attributes } = usePaginated(
    () => '/api/chats',
    (response) => response?.chats,
    {
      items: 15,
      ...queryParams,
    },
    { refreshInterval: 60 * 1000, revalidateOnFocus: true }
  )

  return { chats, ...attributes }
}
