import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import Lottie from 'react-lottie-player'
import Feed from '../components/activity/Feed'
import HomeFeedEmpty from '../components/activity/HomeFeedEmpty'
import EmptyView from '../components/common/EmptyView'
import Loading from '../components/common/Loading'
import OnboardingModal from '../components/common/modal/OnboardingModal'
import MobileTopNav from '../components/common/nav/MobileTopNav'
import Layout from '../components/layouts/Layout'
import Meta from '../components/meta/Meta'
import QuestionnaireCTA from '../components/onboarding/questionnaire/QuestionnaireCTA'
import useUser from '../lib/useUser'
import HomePageEmpty from '../public/lottie/HomePageEmpty.json'

export default function Home() {
  const router = useRouter()
  const { post } = router.query
  const [showPostModal, setShowPostModal] = useState(post === 'new')
  const { user, error } = useUser({ redirectTo: '/signup' })

  useEffect(() => {
    setShowPostModal(post === 'new')
  }, [router, post])

  const emptyInstructions = [
    {
      title: 'What is Community Lab?',
      icon: 'play-filled',
      link: 'https://www.youtube.com/watch?v=GeYHzgqREQg',
      linkLabel: 'Watch Video',
    },
    {
      title: 'Discover topics, groups and people.',
      icon: 'discover',
      link: '/discover',
      linkLabel: 'Go to Discover',
    },
  ]

  if (error) {
    return (
      <EmptyView
        instructions={emptyInstructions}
        title="Welcome to Community Lab! Here you will see content from groups, topics and people you connect with."
        accessory={
          <Lottie
            loop
            animationData={HomePageEmpty}
            play
            style={{ width: 350, height: 350 }}
          />
        }
      />
    )
  }

  if (!user) {
    return <Loading />
  }

  return (
    <>
      <Meta title="Home Feed | What is happening across your groups" />
      <Layout>
        <OnboardingModal user={user} />
        <MobileTopNav title="Home" />

        <div className="sm:w-full md:w-3/4 max-w-7xl mx-auto pb-10">
          <QuestionnaireCTA />
          <Feed
            showButton
            detailedActivityButton
            modalVisible={showPostModal}
            EmptyOverrideComponent={() => <HomeFeedEmpty />}
          />
        </div>
      </Layout>
    </>
  )
}
